import React from "react"
// This import statement has to stay, else the scss isn't included
// noinspection ES6UnusedImports
import styles from "./text.module.scss"
import PropTypes from "prop-types"

class Text extends React.Component {
  render() {
    const { html, title } = this.props
    return (<div className={"card mb-4 " + styles.textCard}>

      <div className={"card-body"}>
        <h1>{title}</h1>
        <div  dangerouslySetInnerHTML={{ __html: html }}/>
      </div>
    </div>)
  }
}

Text.propTypes = {
  html: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default Text

import React from "react"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import Text from "../../components/text/text"
import Moment from "react-moment"
import _ from "lodash"
// This import statement has to stay, else the scss isn't included
// noinspection ES6UnusedImports
import style from './converter.scss'

// TODO this should pull in its own strings from JSON using a static query

class ConverterTemplate extends React.Component {


  constructor(props, context) {
    super(props, context)
  }

  convert(converter_json) {
    var fromIndex = document.getElementById("fromSelect").value
    var toIndex = document.getElementById("toSelect").value
    var value = document.getElementById("value").value
    var fromFactor = converter_json.units[fromIndex].factorSI
    var toFactor = converter_json.units[toIndex].factorSI
    document.getElementById("result").value = value * fromFactor / toFactor
  }

  render() {
    const {
      converter,
      otherConverters, // TODO also link to other converters in other languages (but only if they exist?)
      // TODO induce array of values here
      // TODO otherLanguages
    } = this.props.pageContext
    const general_strings = require("./../../../content/" + converter.frontmatter.lang + "/strings.json")
    const converter_strings = require("./../../../content/" + converter.frontmatter.lang + "/converters/strings.json")
    const converter_json = _.find(require("./../../../content/" + converter.frontmatter.lang + "/converters/converters.json")["converters"], { "path": converter.frontmatter.slug })
    if(converter_json == null){
      console.log(require("./../../../content/" + converter.frontmatter.lang + "/converters/converters.json"));
      console.log({ "path": converter.frontmatter.slug })
    }
    const title = converter.frontmatter.title

    return (
      <Layout lang={converter.frontmatter.lang}>
        <SEO
          title={title}
          description={converter.excerpt}
          lang={converter.frontmatter.lang}
        />
        <article>
          <header>
            <h1>
              {title}
            </h1>
            <p>
              {general_strings.posted + ": "}
              <i><Moment format="YYYY-MM-DD HH:mm" date={converter.frontmatter.created_at}/></i>
              {", " + general_strings.last_updated + ": "}
              {/* TODO this is still broken; also maybe put this in a component */}
              <i><Moment format="YYYY-MM-DD HH:mm" date={converter.frontmatter.updated_at}/></i>
            </p>
          </header>
          <section className="row">
            <div className="col-lg-12">

              <div className="card mb-3 wow fadeIn">
                <div className="card-header font-weight-bold">{converter_strings.header}</div>
                <div className="card-body">
                  <div className={"info-box"}><b>{general_strings.info}: </b>{converter_strings.info}</div>

                  <form>
                    <div className="form-group">
                      <label htmlFor="value">{converter_strings.value}:</label>
                      <input id="value" type="number" step="any" placeholder={converter_strings.value}
                             className="form-control"/>
                    </div>
                    <div className="form-group">
                      <label htmlFor="fromSelect">{converter_strings.from_unit}:</label>
                      <select id="fromSelect" className="form-control">
                        {_.map(converter_json.units, (unit, index) => {
                            return <option value={index}
                                           selected={index === converter_json.initialFromUnit}>{unit.name}</option>
                          },
                        )}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="toSelect">{converter_strings.to_unit}:</label>
                      <select id="toSelect" className="form-control">
                        {_.map(converter_json.units, (unit, index) => {
                            return <option value={index}
                                           selected={index === converter_json.initialToUnit}>{unit.name}</option>
                          },
                        )}
                      </select>
                    </div>
                    <div className="form-group">
                      <button className="btn btn-outline-info"
                              onClick={_ => this.convert(converter_json)}
                              type="button">{converter_strings.calculate}</button>
                    </div>
                    <div className="form-group">
                      <label htmlFor="result">{converter_strings.result}:</label>
                      <input id="result" type="number" step="any" placeholder={converter_strings.result}
                             className="form-control" disabled/>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
          <section className={"row"}>
            <div className="col-lg-12">
              <Text html={converter.html} title={converter.frontmatter.title}/>
            </div>
            <div className="col-lg-12">
              <div className={"card mb-4"}>
                <div className={"card-header"}>
                  {converter_strings.other_converters}
                </div>
                <div className={"card-body"}>
                  <ul>
                    {general_strings.none}
                    {/*TODO other converters */}
                  </ul>
                  {/*TODO all converters
                  <a href={"/"}
                     className="btn btn-light btn-md center">
                  </a>
                */}
                </div>
              </div>
              <div className={"card mb-4"}>
                <div className={"card-header"}>
                  {general_strings.other_languages}
                </div>
                <div className={"card-body"}>
                  <ul>
                    {general_strings.none}
                    {/*TODO this converter in other languages, and build a function to create URLs */}
                  </ul>
                  {/*TODO all languages
                  <a href={"/"}
                     className="btn btn-light btn-md center">
                  </a>
                */}
                </div>
              </div>
            </div>
          </section>
        </article>
      </Layout>
    )
  }
}

export default ConverterTemplate

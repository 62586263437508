import React from "react"
import { Link } from "gatsby"

// This import statement has to stay, else the scss isn't included
// noinspection ES6UnusedImports
import style from './layout.module.scss'
import "bootstrap/dist/css/bootstrap.min.css"
import Navigation from "../header/header"
import Footer from "../footer"

class Layout extends React.Component {
  render() {
    const { children, lang } = this.props

    return (
      <div>
        <Navigation lang={lang}/>
        <main className="mt-5 pt-5">
          <div className="container">
            {children}
          </div>
        </main>
        <Footer/>
      </div>
    )
  }
}

export default Layout

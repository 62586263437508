import React from "react"

import "bootstrap/dist/css/bootstrap.min.css"
// This import statement has to stay, else the scss isn't included
// noinspection ES6UnusedImports
import styles from "./header.module.scss"
import _ from "lodash"
import { useStaticQuery } from "gatsby" // important: case sensitive.

export default (props) => {
  const result = useStaticQuery(graphql`
    query HeaderQuery {
      languages: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(languages)/.*\\\\.md$/"}}) {
        nodes {
          frontmatter {
            name
            slug
            lang
          }
        }
      }
      algorithms: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(algorithms)/.*\\\\.md$/"}}) {
        nodes {
          frontmatter {
            name
            slug
            lang
          }
        }
      }
      converters: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(converters)/.*\\\\.md$/"}}) {
        nodes {
          frontmatter {
            name
            slug
            lang
          }
        }
      }
    }
    `)

  // Only interested in current lang
  const converters = _.filter(result.converters.nodes, x => x.frontmatter.lang === props.lang)
  const languages = _.filter(result.languages.nodes, x => x.frontmatter.lang === props.lang)
  const algorithms = _.filter(result.algorithms.nodes, x => x.frontmatter.lang === props.lang)

  const lang = props.lang ||'en' // TODO E.g. for terms of service, there is no lang property
  const general_strings = require("./../../../content/" + lang + "/strings.json")
  return (
    <header>

      {/* Navbar */}
      <nav className={"navbar fixed-top navbar-expand-lg navbar-light white scrolling-navbar " + styles.navbar}>
        <div className="container">

          {/* Brand */}
          <a className="navbar-brand waves-effect" href={"/" + (props.lang !== "en" ? props.lang : "")}>
            <strong className="blue-text">{general_strings.title}</strong>
          </a>

          {/* Collapse */}
          <button className="navbar-toggler" type="button" data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"/>
          </button>

          {/* Links */}
          <div className="collapse navbar-collapse" id="navbarSupportedContent">

            {/* Left */}
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                <a className="nav-link waves-effect" href="/">{general_strings.home}
                  <span className="sr-only">(current)</span>
                </a>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown"
                   aria-haspopup="true" aria-expanded="false">
                  {general_strings.languages}
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  {_.map(languages, language => {
                    const path = "/" + (props.lang !== "en" ? props.lang + "/" : "") + language.frontmatter.slug
                    return (<a key={path} className="dropdown-item" href={path}>{language.frontmatter.name}</a>)
                  })
                  }
                </div>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown"
                   aria-haspopup="true" aria-expanded="false">
                  {general_strings.algorithms}
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  {_.map(algorithms, algorithm => {
                    const path = "/" + (props.lang !== "en" ? props.lang + "/" : "") + algorithm.frontmatter.slug
                    return (<a key={path} className="dropdown-item" href={path}>{algorithm.frontmatter.name}</a>)
                  })
                  }
                </div>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown"
                   aria-haspopup="true" aria-expanded="false">
                  {general_strings.converters}
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  {_.map(converters, converter => {
                    const path = "/" + (props.lang !== "en" ? props.lang + "/" : "") + converter.frontmatter.slug
                    return (<a key={path} className="dropdown-item" href={path}>{converter.frontmatter.name}</a>)
                  })
                  }
                </div>
              </li>
            </ul>

            {/* Right */}
            <ul className="navbar-nav nav-flex-icons">
              <li className="nav-item">
                <a href="/" className="nav-link">EN</a>
              </li>
              <li className="nav-item">
                <a href="/de" className="nav-link">DE</a>
              </li>
              <li className="nav-item">
                <a href="https://github.com/ClaasM/Algorithms/"
                   className="nav-link border border-light rounded waves-effect">
                  GitHub
                </a>
              </li>
            </ul>


          </div>

        </div>
      </nav>
      {/* Navbar */}

    </header>)
}
